import logo from './logo.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="logo" />
                {/* <h1>Adhyaynam</h1> */}
                <p>
                    Coming Soon
                </p>
                {/* <a
                    className="App-link"
                    href="mailto:email@domain.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    email@domain.com
                </a> */}
            </header>
        </div>
    );
}

export default App;
